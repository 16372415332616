@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
 
}

/* html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  background-color: aqua;
} */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}


body {
  height: 100vh;
  width: 100%;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: "Inter", "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  /* background: #444; */
  padding-top: env(safe-area-inset-top) ;
 
}

.MuiGrid-root{
  margin-top:  0 !important;
  margin-bottom: 0 !important;;
}

 


.slider { 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }
  
  .slider--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
  }
  
  .slider--feature {
    text-align: center;
  }
  
  .feature--title {
    font-size: 2.5rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
  }
  
  .feature--text {
    font-size: 1rem;
    color: #fff;
    margin: 1rem 0;
  }
  
  .slider__btn-right,
  .slider__btn-left {
    background: transparent;
    border: none;
    outline: none;
    font-size: 4rem;
    color: #eee;
    padding: 0 1rem;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
  }
  
  .feature__btn {
    background: #fff;
    text-transform: uppercase;
    border: none;
    color: #444;
    border: 1px solid #444;
    outline: none;
    font-weight: 700;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  
  .slider__btn-left:hover,
  .slider__btn-right:hover {
    transform: scale(0.95);
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fadeIn {
    animation: fadeIn 0.5s;
  }